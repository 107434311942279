<template>
  <v-skeleton-loader
    type="image"
    v-if="!document"
  />
  <v-card v-else>
    <v-card-title>
      {{$t('t.CreditInsurance')}}
      <v-img
        max-height=100
        max-width=300
        contain
        position="left center"
        src="../../../assets/groupama-logo-full.png"
        class="ml-8"
      />
      <v-text-field
        ref="gacNumber"
        class="ml-2"
        :prefix="prefixTitle"
        v-model="computedGacNumber"
      />
    </v-card-title>
    <v-card-text>
      <grid item-min-width="300px">
        <div>
          <v-text-field
            v-if="decisionText"
            readonly
            :label="$t('t.Decision')"
            v-model="decisionText"
          />
          <v-text-field
            v-if="id"
            :label="$t('t.RequestDate')"
            :value="formatDate(document.requestDate)"
            readonly
          />
        </div>
        <div>
          <amount-field
            v-if="computedStandingCreditLimit"
            :label="$t('t.StandingCreditLimit')"
            :currency.sync="groupamaCurrency"
            :amount="computedStandingCreditLimit"
            readonly
          />
          <amount-field
            v-if="id && computedGacNumber"
            :label="$t('t.StandingAmount')"
            :currency="groupamaCurrency"
            :amount="document.standingAmount"
            readonly
          />
        </div>
        <div>
          <amount-temporary-field
            v-if="id && computedGacNumber"
            :label="$t('t.TemporaryCreditLimit')"
            :currency="groupamaCurrency"
            :amount="document.temporaryCreditLimit"
            :start-date="document.decisionStartExceeding"
            :end-date="document.decisionEndExceeding"
            readonly
          />
          <amount-temporary-field
            v-if="id && computedGacNumber"
            :label="$t('t.TemporaryAmount')"
            :currency="groupamaCurrency"
            :amount="document.temporaryAmount"
            :start-date="document.startExceeding"
            :end-date="document.endExceeding"
            readonly
          />
        </div>
        <amount-temporary-field
          :label="$t('t.CreditDirection')"
          :currency="groupamaCurrency"
          :amount.sync="computedCreditDirection"
          :start-date.sync="computedStartCreditDirection"
          :end-date.sync="computedEndCreditDirection"
          :empty-value="0"
        />
        <div>
          <amount-field
            :label="$t('t.CreditLimitTotal')"
            :currency="groupamaCurrency"
            :amount="creditTotal"
            readonly
          />
          <amount-field
            :label="$t('t.CreditUsage')"
            :currency="groupamaCurrency"
            :amount="creditUsage"
            :empty-value="0"
            readonly
          />
          <v-progress-linear
            :value="creditUsagePercent"
            :color="colorUsage"
            height="25"
            rounded
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </div>
        <v-text-field
          v-if="document.comment"
          readonly
          :label="$t('t.Comment')"
          v-model="document.comment"
        />
      </grid>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    AmountField: () => import('@/components/amount-field'),
    AmountTemporaryField: () => import('@/components/amount-temporary-field'),
    Grid: () => import('@/components/grid')
  },
  data () {
    return {
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataGroupama: { gacNumber: null, creditDirection: null, startCreditDirection: null, endCreditDirection: null },
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.GroupamaRequestDetail
    },
    id () {
      return this.dataGroupama?.groupamaRequestId
    },
    colorUsage () {
      if (this.creditUsagePercent < 90) {
        return 'success'
      } else {
        return 'error'
      }
    },
    decisionCodeLibelle () {
      return this.document?.decisionCode ? this.$t(`t.${this.document.decisionCode.toUpperCase()}`) : ''
    },
    decisionText () {
      return this.document?.decisionDate ? this.decisionCodeLibelle + ' ' + this.$t('t.At') + ' ' + this.formatDate(this.document.decisionDate) : ''
    },
    groupamaCurrency () {
      return 'EUR'
    },
    prefixTitle () {
      return this.computedGacNumber ? 'N°Gac' : this.$t('t.NotNamed')
    },
    computedStartCreditDirection: {
      get () {
        return this.dataGroupama?.startCreditDirection ?? this.moment().format('YYYY-MM-DD')
      },
      set (v) {
        this.dataGroupama.startCreditDirection = v
        this.dataDocumentEmit()
      }
    },
    computedEndCreditDirection: {
      get () {
        return this.dataGroupama?.endCreditDirection ?? this.moment().format('YYYY-MM-DD')
      },
      set (v) {
        this.dataGroupama.endCreditDirection = v
        this.dataDocumentEmit()
      }
    },
    computedStandingCreditLimit () {
      return !this.computedGacNumber ? this.dataGroupama.standingCreditLimit : this.document?.standingCreditLimit
    },
    computedCreditDirection: {
      get () {
        return this.dataGroupama?.creditDirection
      },
      set (v) {
        this.dataGroupama.creditDirection = v
        this.dataDocumentEmit()
      }
    },
    computedGacNumber: {
      get () {
        return this.dataGroupama?.gacNumber
      },
      set (v) {
        if (v?.length) {
          this.dataGroupama.gacNumber = v
          this.dataDocumentEmit()
        } else {
          this.dataGroupama.gacNumber = null
          this.dataDocumentEmit()
        }
      }
    },
    creditUsage () {
      return this.dataGroupama?.creditUsage
    },
    creditUsagePercent () {
      return this.dataGroupama?.creditUsagePercent
    },
    creditTotal () {
      return this.dataGroupama?.creditTotal
    }
  },
  methods: {
    async emitDocument () {
      if (this.dataGroupama.creditDirection) {
        this.dataGroupama.startCreditDirection = this.computedStartCreditDirection
        this.dataGroupama.endCreditDirection = this.computedEndCreditDirection
      } else {
        this.dataGroupama.creditDirection = null
        this.dataGroupama.startCreditDirection = null
        this.dataGroupama.endCreditDirection = null
      }

      if (!this.lodash.isEqual(this.dataGroupama, this.groupama)) {
        this.$emit('input', this.lodash.cloneDeep(this.dataGroupama))
      }
    }
  },
  props: {
    value: Object
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataGroupama = v ? this.lodash.cloneDeep(v) : { gacNumber: null, creditDirection: null, startCreditDirection: null, endCreditDirection: null }
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.grid-line
  grid-column 1 / -1
</style>
